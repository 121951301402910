<template>
  <div>
      <q-card flat bordered class="my-card add">
        <q-card-section>
            <!-- <tabulationdate
                :orderList='checkDatas.checkReportFilesList'
                :orderid="checkAllDatas.orderId"
                :prop='"informationFileName"'
                :label='"检查报告"'
            /> -->
             <el-table :data="downloadData.checkReportFilesList" style="width: 100%">
                <el-table-column label="检查报告" prop="informationFileName"></el-table-column>
                <el-table-column label="创建时间" prop="createTime"></el-table-column>
                <el-table-column align="right">
                <template slot="header">
                </template>
                <template slot-scope="scope">
                    <el-button
                    size="mini"
                    type="primary"
                    icon="iconfont icon-xiazai"
                    @click="download(scope.row)"
                    ></el-button>
                </template>
                </el-table-column>
            </el-table>
        </q-card-section>
      </q-card>
      <q-card flat bordered class="my-card add">
        <q-card-section>
            <!-- <tabulationdate
            :orderList='checkDatas.dcmReportFilesList'
            :orderid="checkAllDatas.orderId"
            :prop='"informationFileName"'
            :label='"影像资料"'
            ></tabulationdate> -->
            <el-table :data="downloadData.dcmReportFilesList" style="width: 100%">
                <el-table-column label="影像资料" prop="informationFileName"></el-table-column>
                <el-table-column label="创建时间" prop="createTime"></el-table-column>
                <el-table-column align="right">
                <template slot="header">
                </template>
                <template slot-scope="scope">
                    <el-button
                    size="mini"
                    type="primary"
                    icon="iconfont icon-xiazai"
                    @click="download(scope.row)"
                    ></el-button>
                </template>
                </el-table-column>
            </el-table>
        </q-card-section>
      </q-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    props:{
        downloadData:{
            type: Object,
            required: true,
        }
    },
    created(){},
    data() {
        return {
        };
    },
    components:{},
    computed:{
    },
    watch:{
    },
    methods: {
        //下载检查报告
        async download(row){
            console.log(row);
            const params={
                downloadUrl: row.downloadUrl,
                fileName: row.informationFileName
            }
            this.$q.dialog({
                title: '文件下载',
                message: `你是否要下载${row.informationFileName}文件`,
                ok: { label: '确定',color: 'blue-6'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                const url = window.origin +
                "/order/download/file?downloadUrl=" +
                encodeURIComponent(params.downloadUrl) +
                "&fileName=" +
                encodeURIComponent(params.fileName);
                window.open(url);
            })
        },
    },
};
</script>

<style lang='less' scoped>
.add {
    width: 100%;
    margin-left: 0;
    margin-bottom: .9375rem;
}
</style>
