<template>
    <div>
        <q-form ref="myForm" class="add">
            <div class='maxgao'>
                <q-card flat bordered class="my-card add">
                    <q-card-section>
                        <checkAllContent
                            :checkAllData.sync='checkAllDatas'
                            :checkData.sync='checkDatas'
                        />
                    </q-card-section>
                </q-card>

                <!-- 检查报告影像资料信息综合 -->
                <belowTablebox
                    :checkAllData.sync='checkAllDatas'
                    :checkData.sync='checkDatas'
                    :ordervisible.sync='ordervisible'
                    :reconstructionAgree='false'
                    @refresh='refresh'
                    @yxdownload='yxdownload'
                />
                <!-- 发票信息 -->
                <!-- <q-card flat bordered class="my-card add">
                    <q-card-section>
                        <el-table
                            :data="checkDatas.invoiceList"
                            style="width: 100%"
                        >
                            <el-table-column
                                label="发票信息"
                                prop="invoiceFileName"
                            ></el-table-column>
                            <el-table-column
                                label=""
                                prop="createTime"
                            ></el-table-column>
                            <el-table-column align="right">
                                <template slot="header"> </template>
                                <template slot-scope="scope">
                                    <el-button
                                        size="mini"
                                        type="primary"
                                        icon="iconfont icon-xiazai"
                                        @click="invoicedownload(scope.row)"
                                    ></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </q-card-section>
                </q-card> -->

                
            </div>
            <div class="addbtn">
                <q-btn
                    style="width: 100%"
                    label="确定"
                    color="blue-6"
                    @click="closedialog"
                />
            </div>
        </q-form>

        <!-- 普通影像资料下载链接生成弹窗 -->
        <yxDown
            ref='yxDown'
            :downdata.sync='downdata'
            @downhide='downhide'
        />
    </div>
</template>

<script>
import { Loading } from 'element-ui';

import { informationdownload } from '@/api/components/informationdownload.js' //影像资料下载
import yxDown from '@/components/Down/informationDown.vue' // 影像链接生成弹窗组件

import {
    getprovince,
    getcity,
    gethosplist,
    getdepart,
    getcombo,
    getoptions,
} from "@/api/components/getCity.js"; // 获取城市接口
import { mapState } from "vuex"; //vuex获取数据
import axios from "axios";

import { downloads } from "@/api/Order.js"; // 用户订单新增接口

import { orderalldata } from '@/api/reconstruction.js'; // 用户订单增删改查接口

// import tabulationdate from '@/components/Order/tabulationdate' // 资料表格组件
import surgeryDate from "@/components/Order/surgeryDate"; // 时间选择组件
import selectq from "@/components/Order/select"; // 普通下拉框组件
import selectp from "@/components/Order/select/provinceselect.vue"; // 省份下拉框组件
import selectc from "@/components/Order/select/cityselect.vue"; // 城市下拉框组件
import selecth from "@/components/Order/select/hospselect.vue"; // 医院下拉框组件
import selectd from "@/components/Order/select/departsselect.vue"; // 科室下拉框组件
import comboselect from "@/components/Order/select/comboselect.vue"; // 套餐下拉框组件
import checkAllContent from '@/components/Commoncomponents/checkbox.vue' // 所有文字信息综合
import belowTablebox from '@/components/Commoncomponents/belowTablebox.vue' //检查报告影像资料发票信息综合

import { required } from "@/api/components/required.js"; // 表单验证规则
import { makeDebounce } from "@/utils/debounce.js";  
import { notifys } from '@/api/components/Notify.js'

export default {
    props: {
        checkAllData: {
            //修改订单的数据
            type: Object,
            required: true,
        },
        checkData: {
            //所选的城市
            type: Object,
            required: true,
        },
    },
    created() {},
    data() {
        return {
            ordervisible: false,

            text: "",
            required,

            options:{
                target:'document.body',
                text:'下载链接获取中,如果时间较长请耐心等待',
            },
            
            downdata:{
                downconfig: false,
                downcheckprogress: 0, //进度条
                downcheckprogressLabel: '', //进度百分比
                downfileName: '', // 文件名
                downjindu: null, //用于储存定时器
                downstepName:'', //压缩步骤
                timerList:[],
                downdataA:true, //
                downdataB: true,
            },
        };
    },
    components: {
        selectq, // 普通下拉组件
        selectp, // 省份下拉框组件,
        selectc, //城市下拉框
        selecth, // 医院下拉框
        selectd, // 科室下拉框
        comboselect, //套餐下拉框
        surgeryDate, // 手术时间
        yxDown, //影像链接生成弹窗组件
        checkAllContent, //文字内容综合box
        belowTablebox,//检查报告影像资料发票信息综合
        // tabulationdate // 资料表格
    },
    computed: {
        ...mapState([
            "orderStatus", //订单状态
            "orderType", //订单类型
            "payStatus", // 支付状态
            "isPay", // 代理商是否收款
        ]),
        checkAllDatas: {
            get() {
                return this.checkAllData;
            },
            set(v) {
                this.$emit("update:checkAllData", v);
            },
        },
        checkDatas: {
            get() {
                return this.checkData;
            },
            set(v) {
                this.$emit("update:checkData", v);
            },
        },
    },
    watch: {},
    methods: {
        //刷新影像资料状态
        refresh:makeDebounce(async function(row) {
            console.log('刷新',this.checkAllDatas);
            this.ordervisible = true
            setTimeout(async() =>{
                const {data:res} = await orderalldata({orderId:this.checkAllDatas.orderId})
                if(res.code===20000){
                    notifys('positive',res.message)
                    this.checkDatas.dcmReportFilesList =[] // 清空影像资料
                    console.log(res);
                    // console.log(this.checkData.reject);
                    if(res.data.order.informationList){
                        res.data.order.informationList.map(item=>{
                            if(item.informationType == 1){
                                this.checkDatas.dcmReportFilesList.push(item)
                            }
                        })
                    }
                    this.ordervisible = false
                }else{
                    notifys('negative',res.message)
                    this.ordervisible = false
                }
            },1000)
            
        },300),
      
        
        //影像资料下载
        yxdownload:makeDebounce(async function(row) {
            informationdownload(
                row, //所下载的id信息
                this.downdata
            )
        },500),

        //下载生成进度条关闭时
        downhide(){
            if (this.downdata.downjindu) {
                console.log("结束了");
                this.downdata.timerList.forEach((item, index) => {
                    window.clearInterval(item);
                })
                this.downdata.timerList = [];
                this.downdata.downjindu = 0;
            }
            this.downdata.downconfig =false
            this.downdata.downdataA = false
        },
       
        closedialog() {
            this.$emit("closedialog");
            console.log("A");
        },
    },
};
</script>

<style lang='less' scoped>
.maxgao{
    max-height: 50vh;
    overflow: auto;
}
/deep/.el-dialog__body{
    padding: 0 10px 10px !important;
}
/deep/.el-dialog__header {
    padding: 20px 20px 0 !important;
}
.my-card {
    width: 100%;
    margin-bottom: 0.5rem;
}
.add {
    width: 100%;
    margin-left: 0;
    margin-top: 0.1875rem;
}
.addbtn {
    margin: 0.9375rem 0 0 0;
    display: flex;
    justify-content: center;
}
.q-th {
    height: 0;
}
</style>
