import { createAPI } from "../utils/request"

export const orderalldata = data => createAPI(`/order/recon/get/${data.orderId}`, 'get', data) //订单详细

export const reconList = data => createAPI('order/recon/list', 'get', data) //三维重建列表

export const reconClaim = data => createAPI('order/recon/claim', 'get', data) //三维重建任务批量领取

export const informationCheck = data => createAPI(`order/informationCheck/downloadList/${data.orderId}`, 'get', data) //查询上传资料清单

export const reconResign = data => createAPI(`order/recon/resign/${data.id}`, 'get', data) //放弃任务

export const reconCommit = data => createAPI(`order/recon/commit/${data.id}`, 'get', data) //提交审核

export const reconReport = (data,onUploadProgress) => createAPI(`order/recon/report/${data.get('id')}`, 'post', data,{onUploadProgress}) //上传重建报告


export const recontestnotify = data => createAPI(`order/recon/test/notify/${data.accessCode}`, 'get', data) // 测试三维重建完成通知接口