<template>
  <div id="OrderReconstruction" class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
      <q-breadcrumbs-el label="订单管理" icon="iconfont icon-dingdanguanli" />
      <q-breadcrumbs-el label="三维重建" icon="iconfont icon-sanwei" to="order/reconstruction/list" />
    </q-breadcrumbs>
    <div class='OrderReconstructionbox' id='OrderReconstructionbox'>
      <!-- 搜索组件 -->
      <q-card class="my-card" style="margin-bottom: 10px;">
        <q-card-section>
          <div class="row" id="searchbox">
            <div class="col-9 search">
              <q-input class='operation' :dense='true' outlined  v-model="queryData.patientName" label="患者姓名">
                <template v-if="queryData.patientName" v-slot:append>
                  <q-icon name="close" @click.stop="queryData.patientName = ''" class="cursor-pointer" style="font-size: 16px;"/>
                </template>
              </q-input>
              <selectq class='operation' :options='reconStatus' :model.sync='informationmodel' :label='"资料处理状态"' />

              <el-button
                :disabled='Permissionslist.filter(item=>item==="reconstruction.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='search'
              >
              搜索
              </el-button>
              <el-button
                :disabled='Permissionslist.filter(item=>item==="reconstruction.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='chongzhi'
              >
              重置
              </el-button>
              
            </div>
            <div class="col  addbtn">

              <!-- 测试 -->
              <!-- <el-button 
                size='medium'
                type="primary"
                @click='ceshi=true'>
                更改状态
              </el-button> -->

              <!-- :disabled='multipleSelection.length==0? true : false' -->
              <el-button
                :disabled='Permissionslist.filter(item=>item==="reconstruction.edit").length>0?false:true'
                type="primary"
                size='medium'
                @click='Batchreceive'
              >
              领取任务
              </el-button>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <!-- 列表区域 -->
        <q-card class="my-card">
          <!-- 筛选区域 -->
          <!-- <q-card-section class='tabletop'>
            
          </q-card-section> -->
          <q-card-section>
            <el-table
            v-loading="ordervisible"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(55,55,55,0.2)"
            ref="multipleTable"
            :data="reconlist"
            @sort-change="sortthiscolumn"
            tooltip-effect="dark"
            style="width: 100%"
            :max-height="tableHeight"
            @selection-change="handleSelectionChange"
            >
            <template slot="empty">
              {{nodatetype}}
            </template>
            <el-table-column align='center' type="selection" width="55" fixed="left"></el-table-column>
            <!-- 订单号 -->
            <el-table-column sortable="custom" show-overflow-tooltip v-if="orderID" align='center' prop="orderId" label="订单号" min-width="93px"></el-table-column>
            <!-- 患者姓名 -->
            <el-table-column sortable="custom" v-if="patient" align='center' prop="patientName" label="患者姓名" min-width="104px"></el-table-column>
            <!-- 加急订单 -->
            <el-table-column v-if="Urgent && urgenthide" align='center' width='70px'>
              <template slot-scope="{row}">
                <el-tag v-if='row.urgentStatus' type="danger" style='padding:0 5px;'>加急</el-tag>
                <el-tag v-if='row.weekDay' type="danger" style='padding:0 5px;'>周末</el-tag>
              </template>
            </el-table-column>
            <!-- 主管医师 --> 
            <el-table-column v-if="attendingDoctor" sortable="custom" show-overflow-tooltip align='center' prop="attendingDoctor" label="主管医师" min-width='104px'></el-table-column>
            <!-- 资料审核员 -->
            <el-table-column sortable="custom" v-if="informationU" align='center' prop="informationCheckUser" label="资料审核员" min-width="114px"></el-table-column>
            <!-- 重建人员 -->
            <el-table-column sortable="custom" v-if="reconU" align='center' prop="reconUser" label="重建人员" min-width="104px"></el-table-column>
            <!-- 结果审核员 -->
            <el-table-column sortable="custom" v-if="checkU" align='center' prop="checkUser" label="结果审核员" min-width="114px">
              <template slot-scope="checkU">
                {{ checkU.row.checkUser?checkU.row.checkUser: ''}}
              </template>
            </el-table-column>
            <!-- 套餐名称 -->
            <el-table-column sortable="custom" v-if="combo" show-overflow-tooltip align='center' prop="comboName" label="套餐名称" min-width="104px"></el-table-column>
            <!-- 重建报告 -->
            <el-table-column sortable="custom" v-if="reconR" show-overflow-tooltip align='center' prop="reconstructionReport" label="重建报告" min-width="104px">
              <template slot-scope="recon">
                <span v-if='recon.row.reconstructionReport' @click='xzrecon(recon.row)' style="cursor: pointer">
                  <q-icon name="iconfont icon-xiazai"/>
                  {{recon.row.reconstructionReport}}
                </span>
              </template>
            </el-table-column>
            <!-- 驳回信息 -->
            <el-table-column sortable="custom" v-if='bohui' show-overflow-tooltip align='center' prop="comments" label="驳回信息"  min-width="104px">
              <template slot-scope="bohui">
                  {{ (bohui.row.comments ? bohui.row.comments : '--') }}
              </template>
            </el-table-column>
            <!-- 重建时间 -->
            <el-table-column sortable="custom" v-if="mTime" align='center' prop="modifyTime" label="重建时间" min-width='155px' ></el-table-column>
            <!-- 手术时间 -->
            <el-table-column sortable="custom" v-if="sTime" align='center' prop="surgeryDate" label="手术时间" min-width='155px' ></el-table-column>
            <!-- 创建时间 -->
            <el-table-column sortable="custom" v-if="cTime" align='center' prop="createTime" label="创建时间" min-width='155px' ></el-table-column>
            <!-- 提取码 -->
            <el-table-column sortable="custom" v-if="accessCode" align='center' prop="accessCode" label="提取码" min-width="93px"></el-table-column>
            <!-- 资料处理状态 -->
            <el-table-column sortable="custom" v-if="informationS" align='center' prop="informationStatus" label="资料处理状态" min-width="143px">
              <template slot-scope="Types">
                <q-chip square :color="checkStatuscolor[Types.row.informationStatus]" text-color="white">
                  {{ (informationStatus.filter(item => item.key == Types.row.informationStatus ).pop() || {}).value }}
                </q-chip>
              </template>
            </el-table-column>
            <el-table-column align='center' min-width="230" width="250" fixed="right">
              <template slot="header">
                <div class='newCaozuobox'>
                  <div class='czTit'>操作</div>
                  <div class='czpopoverbtn'>
                    <el-popover
                      placement="bottom"
                      width="160"
                      trigger="click"
                    >
                        <q-checkbox v-model="orderID" label="订单号" color="cyan" />
                        <q-checkbox v-model="patient" label="患者姓名" color="cyan" />
                        <q-checkbox v-model="Urgent" label="订单加急" color="cyan" />
                        <q-checkbox v-model="attendingDoctor" label="主管医师" color="cyan" />
                        <q-checkbox v-model="informationU" label="资料审核员" color="cyan" />
                        <q-checkbox v-model="reconU" label="重建人员" color="cyan" />
                        <q-checkbox v-model="checkU" label="结果审核员" color="cyan" />
                        <q-checkbox v-model="combo" label="套餐名称" color="cyan" />
                        <q-checkbox v-model="reconR" label="重建报告" color="cyan" />
                        <q-checkbox v-model="bohui" label="驳回信息" color="cyan" />
                        <q-checkbox v-model="mTime" label="重建时间" color="cyan" />
                        <q-checkbox v-model="sTime" label="手术时间" color="cyan" />
                        <q-checkbox v-model="cTime" label="创建时间" color="cyan" />
                        <q-checkbox v-model="accessCode" label="提取码" color="cyan" />
                        <q-checkbox v-model="informationS" label="资料处理状态" color="cyan" />
                      <el-button icon="el-icon-s-grid" size='medium' slot="reference" style="padding: 5px 10px;border: none;">
                        <i class="el-icon-caret-bottom"></i>
                      </el-button>
                    </el-popover>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <!-- 领取任务  :disable='scope.row.checkStatus==0? false : true'-->
                <q-btn
                  :disable='Permissionslist.filter(item=>item==="reconstruction.edit").length>0 && scope.row.informationStatus==3? false : true'
                  class="czbtn" 
                  size="sm" 
                  color="blue" 
                  icon="iconfont icon-lingqu"
                  @click='Reconclaim(scope.row)'>
                  <q-tooltip content-class="bg-blue" :offset="[10, 10]">
                    领取任务
                  </q-tooltip>  
                </q-btn> 
                <!-- 提交审核 :disable='scope.row.checkStatus==0? true : false'-->
                <q-btn 
                  :disable='Permissionslist.filter(item=>item==="reconstruction.edit").length>0 && (scope.row.informationStatus==5 || scope.row.informationStatus==8)? false : true'
                  class="czbtn"  
                  size="sm" 
                  color="green-5" 
                  icon="iconfont icon-tijiao"
                  @click='Checkagree(scope.row)'>
                  <q-tooltip content-class="bg-green-5" :offset="[10, 10]">
                    提交审核
                  </q-tooltip>  
                </q-btn>
                <el-popover
                    width='80'
                    trigger="hover">
                    <!-- 资料下载 -->
                    <q-btn
                      :disable='Permissionslist.filter(item=>item==="reconstruction.edit").length>0? false: true'
                      style="width:100%;"
                      class="czbtn2"  
                      size="sm" 
                      color="yellow-10" 
                      icon="iconfont icon-chakan"
                      @click='checkorder(scope.row)'
                      >
                      订单详情
                    </q-btn>
                    <!-- 重建报告上传 -->
                    <q-btn
                      :disable='Permissionslist.filter(item=>item==="reconstruction.edit").length>0 && scope.row.informationStatus!=3? false : true'
                      style="width:100%;"
                      class="czbtn2"
                      size="sm"
                      color="yellow-10"
                      icon="iconfont icon-icon--"
                    @click="report(scope.row)"
                    >
                      重建报告上传
                    </q-btn>
                    <!-- 放弃任务-->
                    <q-btn 
                      :disable='Permissionslist.filter(item=>item==="reconstruction.edit").length>0 && scope.row.informationStatus==4? false : true' 
                      style="width:100%;"
                      class="czbtn2" 
                      size="sm" 
                      color="yellow-10" 
                      icon="iconfont icon-icon_function_fangqi"
                      @click='fangqi(scope.row)'
                    >
                      放弃任务
                    </q-btn>
                    <el-button class="morebtn" size="mini" icon="el-icon-more" slot="reference">更多</el-button>
                  </el-popover>
                
              
                
              </template>
            </el-table-column>
            
          </el-table>
          <!-- 分页组件 -->
          <elementPagination class="Paginationstyle" :total='total' :queryData='queryData' @getList='getreconList()' @changepageSize='changepageSize' @changePage='changePage'/>
          </q-card-section>
        </q-card>

        <!-- 重建审核组件 -->
        <!-- <el-dialog :visible.sync="agreeconfirm" width="70%" title="重建审核" :close-on-click-modal='false' @close="agreeconfirm = false">
          <checktask
            class="el-dialog__body"
            ref='checkagree'
            :checkAlllist.sync='checkAlllist'
            :checkAllData.sync='agreeAllData'
            :checkData.sync='agreeData'
            @closedialog='agreeconfirm = false'
            @checksuccess='getinformationCheckList()'
          >
          </checktask>
        </el-dialog>  -->
        <el-dialog :visible.sync="downloadconfirm" width="55%" title="资料清单" :close-on-click-modal='false' @close="downloadconfirm = false">
          <downloadl
            :downloadData='downloadData'
          />
        </el-dialog>

        <!-- 上传发票 -->
        <el-upload
          class="upload-demo upload"
          ref="invoiceupload"
          action=""
          style='display:none'
          :show-file-list="true"
          :on-change='invoiceFileschange'
          :file-list="invoiceFilesList"
          :auto-upload="false"
        >
            <el-button slot="trigger" size="small" id='Ibtn' type="primary"></el-button>
        </el-upload>

        <!-- 上传文件提示区 -->
        <q-dialog v-model="alert" persistent>
          <q-card style="width: 400px;">
            <q-card-section>
              <div class="wenjian">文件: {{fileName}}</div>
            </q-card-section>

            <q-card-section class="q-pt-none">
              <q-linear-progress size="25px" :value="checkprogress" color="primary">
                <div class="absolute-full flex flex-center">
                  <q-badge color="white" text-color="primary" :label="checkprogressLabel" />
                </div>
              </q-linear-progress>
            </q-card-section>
            <q-card-section class="q-pt-none">
              
            </q-card-section>
          </q-card>
        </q-dialog>

        <!-- 查看弹窗 -->
        <el-dialog :visible.sync="checkconfirm" width="70%" title="订单详情" :close-on-click-modal='false' @close="checkconfirm = false">
          <ordercheck
            v-if="checkconfirm"
            class="el-dialog__body"
            ref='ordercheck'
            :checkAllData.sync='checkAllData'
            :checkData.sync='checkData'
            @closedialog='closecheckdialog'
          >
          </ordercheck>
        </el-dialog> 

        <!-- 测试 -->
        <!-- <el-dialog :visible.sync="ceshi" width="40%" title="更改状态" :close-on-click-modal='false' @close="ceshiguanbi">
          <q-input v-model="text" label="Standard" />
              <q-btn
                class="czbtn"  
                size="sm" 
                type="primary"
                @click='genggai'>
                更改
              </q-btn>
        </el-dialog>  -->

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import selectq from '@/components/Order/select/index2.vue' // 下拉框组件
import elementPagination from '@/components/ElementUI/elementPagination.vue'; // 分页组件
import { makeDebounce } from "@/utils/debounce.js";
import downloadl from '@/components/Reconstruction/downloadlist.vue' // 下载资料组件
import ordercheck from '@/components/Reconstruction/checkOrder.vue' // 查看订单详细组件
import { optionget } from '@/api/components/checkcitys.js'; // 获取城市接口

import { reconList,reconClaim,reconCommit,reconResign,informationCheck,reconReport,recontestnotify,orderalldata } from '@/api/reconstruction.js'; // 用户订单增删改查接口
import { discountCodeCheck } from '@/api/Discount.js'

import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度
import jwt from "jsonwebtoken";
import {
  getItem,
} from '@/utils/storage.js'

import { mapState } from 'vuex'
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
    this.getreconList()
    document.onkeydown=e=>{
      let _key = window.event.keyCode;
      if(_key===13){
        this.search()
      }  
    }
  },
  data() {
    return {
      tableHeight:200, // 列表最大高度

      ordervisible: false,
      nodatetype:'', //无内容时显示
      checkAlllist:{
        province:[],
        city:[],
        hosp:[],
        depart:[],
        combo:[],
      },
      checkStatuscolor:{ //资料状态
        0: 'primary', //资料待审核
        1: 'primary', //资料审核中
        2: 'negative', //资料被驳回
        3: 'indigo-5', //资料待重建
        4: 'indigo-5', //三维重建中
        5: 'indigo-5', //重建结果已上传
        6: 'indigo-5', //重建结果待审核
        7: 'indigo-5', //重建结果审核中
        8: 'negative', //重建结果被驳回
        9: 'positive', //重建完成
      },

      patient: true, //控制患者列
      combo: true, //控制套餐列
      reconR: true, //控制重建报告
      mTime: true, //控制重建时间 
      accessCode: true, //控制提取码
      informationS: true, //控制资料处理状态
      Urgent: true, //控制订单加急

      attendingDoctor:false, //主管医师
      bohui: false, // 控制驳回信息
      informationU: false, //控制资料审核员
      reconU: false, //控制重建人员
      checkU: false, //控制重建结果审核员
      sTime: false, //控制手术时间
      cTime: false, //控制创建时间 
      orderID: false, //控制订单号
      
      alert: false, // 控制上传文件提示
      checkprogress: 0, //进度条
      checkprogressLabel: '', //进度百分比
      fileName: '', // 文件名
      invoiceFilesList:[], // 文件传输绑定数组
      invoiceList:[], //所选重建报告文件
      orderId: '', // 上传文件所需id

      // 分页设置
      queryData:{ // element分页信息
        page: 1,
        pageSize: 10,
        patientName:'', // 患者名称
        // orderId: '', // 订单号
      },
      sortField:'', // 控制那列排序
      sortType:'', // 控制排序类型

      informationmodel:'', //重建审核状态
      total:0, //总条数
      multipleSelection:[], //复选框选择值
      reconlist: [],
      urgenthide: false,//是否展示加急列
      
      downloadconfirm: false, // 控制下载资源
      downloadData: {
        checkReportFilesList:[],//检查报告资料 
        dcmReportFilesList:[], //影像资料报告
      },


      //订单详细所需数据
      checkconfirm: false,
      checkAllData:null, // 查看订单的总信息
      checkData:{ // 查看用户所需数据
        // provinceoptions: null, // 省份所选项
        // cityoptions: null, // 城市所选项
        // hospoptions: null, // 医院所选项所选项
        // departoptions: null, // 科室所选项
        // combooptions: null, // 套餐所选项
        orderTypeoptions: '', // 订单类型所选项
        orderStatusoptions: '', // 订单状态所选项
        payStatusoptions: '', // 支付状态
        reject:'', // 驳回信息
        checkReportFilesList:[],//检查报告资料
        dcmReportFilesList:[], //影像资料报告
        invoiceList: [], // 发票信息
        ispayoptions: '', // 代理商是否付款
        surgeryDate:'', //手术时间
        payId:'',// 订单流水

        returnedAmtDisable: null, //控制是否显示回款金额
        userType:'', //用户类型
        discountCode:'', //暂时的优惠码
        discountPercent:'', //折扣力度
        urgentAmt:'', //加急金额
        orderReturnedAmt:'', //回款金额
      },

      text:'',

      ceshi: false, // 
    };
  },
  components:{
    elementPagination, //分页
    // checktask, // 审核组件
    downloadl, // 下载资料组件
    selectq,
    ordercheck, //订单详细
  },
  computed:{
       ...mapState([
        'informationStatus', // 资料审核状态
        'reconStatus', //三维重建状态
        'Permissionslist', // 权限词典
        'orderStatus', //订单状态
        'orderType',//订单类型
        'payStatus', // 支付状态
        'isPay', //代理商付款状态
        'Permissionslist' // 权限词典
      ]),
  },
  watch:{
  },
  methods: {
    // 获取三维重建列表
    getreconList(ordername,sortType){
      this.nodatetype=''
      this.ordervisible = true
      this.urgenthide = false //默认隐藏加急列
      setTimeout(async() =>{
        const { data: res } = await reconList({
          ...this.queryData,
          informationStatus: this.informationmodel.key,
          sortField: ordername, //给哪一列排序
          sortType: sortType, //排序类型升序或降序
        })
        if(res.code===20000){
          this.reconlist = res.data.orders
          this.reconlist.map((e)=>{
            if(e.urgentStatus || e.weekDay){
              //有加急订单 或者是有 周末订单
              this.urgenthide = true //显示加急列
            }
          })
          this.total = res.data.totals * 1
          console.log(res); 
        }
        
        let height = document.getElementById('searchbox').offsetHeight + 60
        this.tableHeight = getHeight('OrderReconstructionbox',height)

        this.ordervisible = false
        this.nodatetype='暂无数据'
      },500)
      
    },
    // 排序函数
    sortthiscolumn(column){
      console.log(column);
      console.log(column.order);
      if(column.order=='ascending'){
        // ascending 为升序
        this.getreconList(column.prop,1)
        this.sortField = column.prop
        this.sortType = 1
      }else if(column.order=='descending'){
        // ascending 为降序
        this.getreconList(column.prop,0)
        this.sortField = column.prop
        this.sortType = 0
      }else{
        // 默认按照时间排序排序
        this.getreconList('createTime',0)
        this.sortField = 'createTime'
        this.sortType = 0
      }
    },
    //重置
    chongzhi:makeDebounce(function(){
      this.queryData.page = 1
      this.queryData.patientName = ''
      this.informationmodel = ''
      this.getreconList()
    },200),
    // 搜索
    search:makeDebounce(function(){
      this.queryData.page = 1
      this.getreconList(this.sortField,this.sortType)
    },200),
    // 点击上传重建报告
    report(row){
      this.orderId=row.id
      const I = document.getElementById('Ibtn')
      I.click()
    },
    invoiceFileschange(file, fileList){ //发票上传文件发生改变时
      this.fileName = file.name
      this.invoiceList.push(file.raw)
      this.checkReportsave()
      this.invoiceList=[]
      fileList=[]
    },
    async checkReportsave(){ 
      this.alert = true
      const filedata = new FormData()
      filedata.append('reportFile',this.invoiceList[0])
      filedata.append('id',this.orderId)
      // console.log(filedata);
      const { data: res } = await reconReport(filedata, event=>{
        // console.log(event);
        this.checkprogress = event.loaded/event.total
        this.checkprogressLabel=`文件已上传${((event.loaded/event.total)*100).toFixed(2)}%`
      })
      console.log(res);
      if(res.code ==20000){
        this.$refs.invoiceupload.clearFiles()
        notifys('positive',res.message)
      }else{
        notifys('negative',`文件${this.invoiceList[0].name},${res.message}`)
      }
      this.alert = false
      this.getreconList()
    },
    //点击下载重建报告
    xzrecon(row){
      const params={
        downloadUrl: row.downloadUrl,
        fileName: row.reconstructionReport
      }
      // 你是否要下载${row.reconstructionReport}
      this.$q.dialog({
          title: '重建报告下载',
          message: `你是否要下载此报告`,
          ok: { label: '确定',color: 'blue-6'},
          cancel: { label: '取消',flat: true},
      }).onOk(async() => {
          const url = window.origin +
          "/order/download/file?downloadUrl=" +
          encodeURIComponent(params.downloadUrl) +
          "&fileName=" +
          encodeURIComponent(params.fileName);
          console.log(url);
          window.open(url);
      })
    },
    // 单个领取
    Reconclaim(row){
      this.$q.dialog({
        title: '领取任务',
        message: `你确定要领取此重建任务吗?`,
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
          const { data: res } = await reconClaim({ids: row.id})
          // console.log(res);
          if(res.code===20000){
            this.getreconList()
            notifys('positive',res.message)
          }else{
            notifys('negative',res.message)
          }
        }catch (e){
          console.log(e);
        }
      })
    },
    // 点击下载资料时
    download:makeDebounce(async function(row){
      this.downloadData.checkReportFilesList=[]
      this.downloadData.dcmReportFilesList=[]
      console.log(row);
      const { data: res } = await informationCheck({ orderId: row.orderId})
      console.log(res);
      if(res.data.informationList!=[]){
        res.data.informationList.map(item=>{
          if(item.informationType == 0){
            this.downloadData.checkReportFilesList.push(item)
          } else{
            this.downloadData.dcmReportFilesList.push(item)
          }
        })
      }
      this.downloadconfirm= true
    }),
    //点击放弃
    async fangqi(row){
      this.$q.dialog({
        title: '放弃重建任务',
        message: `你确定要放弃此重建任务吗?`,
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
          const { data: res } = await reconResign({id: row.id})
          console.log(res);
          if(res.code===20000){
            this.getreconList()
            notifys('positive',res.message)
          }else{
            notifys('negative',res.message)
          }
        }catch (e){
          console.log(e);
        }
      })
    },
    //点击提交审核时
    async Checkagree(row){
      this.$q.dialog({
        title: '提交重建任务',
        message: `你确定要提交此重建任务吗?`,
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
          const { data: res } = await reconCommit({id: row.id})
          console.log(res);
          if(res.code===20000){
            this.getreconList()
            notifys('positive',res.message)
          }else{
            notifys('negative',res.message)
          }
        }catch (e){
          console.log(e);
        }
      })
    },
    //批量领取任务
    async Batchreceive(){
      const data = this.multipleSelection.map(item=>{
        return item.id
      })
      this.$q.dialog({
        title: '领取任务',
        message: `你确定要领取已勾选的重建任务吗?`,
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
          const { data: res } = await reconClaim({ids: data.join(',')})
          // console.log(res);
          if(res.code===20000){
            this.getreconList()
            notifys('positive',res.message)
          }else{
            notifys('negative',res.message)
          }
        }catch (e){
          console.log(e);
        }
      })
    },
    // 复选框被选择时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页发送变化函数
    changepageSize(val){
      this.queryData.pageSize = val
      this.getreconList(this.sortField,this.sortType)
    },
    changePage(val){
      this.queryData.page = val
      this.getreconList(this.sortField,this.sortType)
    },


    //关闭查看订单时
    closecheckdialog(){
      this.checkconfirm = false
    },
    // 点击查看时
    checkorder: makeDebounce(async function(row) {
      const token = getItem('token');
      this.checkData.userType = jwt.decode(token).userType

      const {data:res} = await orderalldata({orderId:row.orderId})
      console.log(res);
      if(res.code===20000){
        this.checkAllData = res.data.order //储存总数据
        console.log(this.checkAllData);

        // 控制是否显示回款金额 只有商务和系统管理员可以显示回款金额
        this.checkData.returnedAmtDisable = this.checkData.userType ==2 || this.checkData.userType ==3 ? true : false, 

        // 回款金额
        this.checkData.orderReturnedAmt = this.checkAllData.orderReturnedAmt ? this.checkAllData.orderReturnedAmt: ''
        // 加急金额
        this.checkData.urgentAmt = this.checkAllData.urgentAmt ? this.checkAllData.urgentAmt: ''
        // 优惠码
        this.checkData.discountCode = this.checkAllData.discountCode ? this.checkAllData.discountCode: ''
        //折扣力度
        this.checkData.discountPercent = this.checkAllData.discountPercent ? this.checkAllData.discountPercent: ''
        //支付流水号
        this.checkData.payId = this.checkAllData.orderPay ? this.checkAllData.orderPay.payId : ''
        
        this.checkData.checkReportFilesList = [] //清空检查报告
        this.checkData.dcmReportFilesList =[] // 清空影像资料
        this.checkData.invoiceList=[] // 清空发票信息
        this.checkconfirm = true
        
        this.checkData.orderTypeoptions=this.checkAllData.orderType!=undefined? optionget(this.orderType,this.checkAllData.orderType) : ''  //订单类型

        this.checkData.ispayoptions=this.checkAllData.isPay!=undefined? optionget(this.isPay,this.checkAllData.isPay) : ''  //代理商是否付款

        this.checkData.surgeryDate=this.checkAllData.surgeryDate!=undefined? this.checkAllData.surgeryDate : ''  //手术时间

        this.checkData.orderStatusoptions=optionget(this.orderStatus,this.checkAllData.orderStatus) // 订单状态
        this.checkData.payStatusoptions=optionget(this.payStatus,this.checkAllData.payStatus) // 支付状态
        this.checkData.reject=''
        // console.log(this.checkAllData.reconstructionVo);
        if(this.checkAllData.reconstructionVo){
          this.checkData.reject = `${this.checkAllData.reconstructionVo.informationComments}`
        }
        if(this.checkAllData.orderCheck){
          this.checkData.reject =  `
          ${this.checkData.reject}
          ${this.checkAllData.orderCheck.comments}`
        }
        // console.log(this.checkData.reject);
        if(this.checkAllData.informationList){
          this.checkAllData.informationList.map(item=>{
            if(item.informationType == 0){
              this.checkData.checkReportFilesList.push(item)
            } else{
              this.checkData.dcmReportFilesList.push(item)
            }
          })
        }
        if(this.checkAllData.invoice){
          this.checkData.invoiceList.push(this.checkAllData.invoice)
        }
      }else{
        notifys('negative',res.message)
      }
    }),
  },
};
</script>

<style lang='less' scoped>
#OrderReconstruction{
  height: 100%;
}
.OrderReconstructionbox{
  height: 90%;
}
.search{
  display: flex;
  flex-direction: row ;
  align-items: flex-end;
  flex-wrap: wrap;
  .operation{
    margin: .625rem .625rem 0 .625rem;
    width: 12.5rem;
  }
}
.q-card__section--vert {
  padding: 10px;
}
/deep/.el-table td{
  padding: 8px 0 !important;
}
.newCaozuobox{
  // display: flex;
  // justify-content: space-between;
  .czTit{
    float: left;
    text-align: right;
    width:50%;
  }
  .czpopoverbtn{
    float: left;text-align: right;width:50%;
  }
}
.tabletop{
  display: flex;
  flex-direction:row-reverse;
  padding: .3125rem 1rem;
}
.addbtn{
  display: flex;
  flex-direction: row ;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
}
.resetquery{
  margin: .625rem .625rem 0 .625rem;
}
.czbtn{
  margin-right: .75rem;
}
.Paginationstyle{
  margin-top: .9375rem;
}
.upload{
  display: none;
}
/deep/.el-dialog__body{
  padding: 0 10px 10px !important;
}

.czbtn{
  margin-right: .75rem;
}
.czbtn2{
  margin: .3125rem 0;
}
.morebtn{
  height: 25.72px;
  margin: 0;
  border: none;
  background-color:#fdd835 !important;
  color: #fff;
}
</style>
